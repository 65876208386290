.slide1 {
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 60px;
}

.slide1 a {
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .slide1 {
        grid-column-gap: 25px;
        grid-row-gap: 50px;
    }
}