.callContainer {
    height: 88.8vh;
    position: relative;
    top: 2.2vh;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    color: black;
    border-radius: 20px 20px 30px 30px;
}

@media screen and (max-width: 600px) {
    .callContainer {
        height: 75.5vh;
    }
}