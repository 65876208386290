.formCont {
    height: 100%;
    padding: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.formGroup {
    margin: 20px 0;
    position: relative;
    padding: 10px 0 0;
    font-family: 'Fuzzy Bubbles', cursive;
}

.formField {
    font-family: inherit;
    width: 100%;
    border: 0;
    color: blue;
    border-bottom: 2px solid #000000;
    outline: 0;
    font-size: 1.3rem;
    padding: 14px 0 0 0;
    background: transparent;
    transition: border-color 0.2s;
}

.formField::placeholder {
    color: transparent;
}

.formField:placeholder-shown~.formLabel {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
}

.formLabel {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
}

.formField:focus {
    color: #000000;
    padding-bottom: 6px;
    font-weight: 700;
    border-bottom: 3px solid blue;
    border-image-slice: 1;
}

.formField:focus~.formLabel {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: blue;
    font-weight: 700;
}

.formSubmit {
    margin-top: 20%;
}

.submit:before {
    background: #77ffa0;
}

.submit span {
    color: #006400;
}

.submit:hover:before {
    background: #2cff6b;
}

.button {
    position: relative;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    display: flex;
    align-items: center;
}

.button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
}

.button span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.05em;
}

.button svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    transform: translateX(-5px);
    transition: all 0.3s ease;
}

.button:hover:before {
    width: 100%;
}

.button:hover svg {
    transform: translateX(0);
}

.button:active {
    transform: scale(0.95);
}