.contactsCont {
    height: 100%;
    background-color: rgb(196, 196, 196);
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.contactsCont::-webkit-scrollbar {
    width: 5px;
}

.contactsCont::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.contactsCont::-webkit-scrollbar-thumb {
    background: #0000ffbb;
    border-radius: 10px;
}