.bodyContainer,
.notch {
    display: flex;
    justify-content: center;
    align-items: center;
}

.border1 {
    width: 53vh;
    background: url("../../assets/wallpaper.jpg");
    background-size: contain;
    background-position: center;
    border: 14px solid #000000;
    border-radius: 40px;
    position: relative;
}

.notch {
    margin: auto;
    width: 30vh;
    height: 3.5vh;
    background-color: black;
    border: 1px solid rgb(255, 255, 255);
    border-top: 0;
    border-radius: 0 0 40px 40px;
    z-index: 1;
}

.notch img {
    width: 8vh;
    height: 2vh;
}

@media screen and (max-width: 600px) {
    .border1 {
        width: 79vw;
    }
    .notch {
        width: 43vw;
        height: 3vh;
    }
}