@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&display=swap');
.callFooter {
    margin: auto;
    width: 100%;
    background-color: white;
    bottom: 0%;
    border-radius: 0 0 30px 30px;
    border-top: 1px solid grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 15%;
}

.callKeypad a,
.callRecents a,
.callContacts a {
    margin: 10% 0 10% 0;
    font-size: 0.9rem;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Fuzzy Bubbles', cursive;
}

@media screen and (max-width: 600px) {
    .callFooter {
        grid-gap: 20px;
    }
}