@import url(https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Vujahday+Script&&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&family=Vujahday+Script&&display=swap);
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-image: linear-gradient(225deg, #1e3c72 10%, #2a5298 90%);
    overflow: hidden;
}

.body {
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

@media screen and (max-width: 600px) {
    body {
        background: none;
    }
    .body {
        margin-top: 7%;
    }
}
.bodyContainer,
.notch {
    display: flex;
    justify-content: center;
    align-items: center;
}

.border1 {
    width: 53vh;
    background: url(/static/media/wallpaper.e17e108d.jpg);
    background-size: contain;
    background-position: center;
    border: 14px solid #000000;
    border-radius: 40px;
    position: relative;
}

.notch {
    margin: auto;
    width: 30vh;
    height: 3.5vh;
    background-color: black;
    border: 1px solid rgb(255, 255, 255);
    border-top: 0;
    border-radius: 0 0 40px 40px;
    z-index: 1;
}

.notch img {
    width: 8vh;
    height: 2vh;
}

@media screen and (max-width: 600px) {
    .border1 {
        width: 79vw;
    }
    .notch {
        width: 43vw;
        height: 3vh;
    }
}
.footerContainer {
    position: relative;
    margin: auto;
    height: 11.6%;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0px;
}

@media screen and (max-width: 600px) {
    .footerContainer {
        height: 9.6%;
        margin: auto;
        grid-column-gap: 0px;
    }
}
.appMain {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.appNoti {
    position: absolute;
    right: 5%;
    top: -13%;
}

.appNoti svg,
.appNoti2 svg {
    width: 30%;
    height: 30%;
}

.appNoti2 {
    position: absolute;
    right: -2%;
    top: -13%;
}

.appIcon,
.appName {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.appIcon {
    width: 50px;
    height: 50px;
    background-color: rgba(102, 102, 102, 0.5);
    border-radius: 35%;
}

.appName {
    margin-top: 10%;
    font-size: 0.8em;
    color: white;
    font-family: 'Fuzzy Bubbles', cursive;
}

.appName a {
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .appNoti {
        right: -2%;
        top: -18%;
    }
    .appNoti svg,
    .appNoti2 svg {
        width: 25%;
        height: 25%;
    }
    .appNoti2 {
        right: -13%;
        top: -19%;
    }
    .appIcon {
        width: 40px;
        height: 40px;
    }
    .appName {
        font-size: 0.6em;
    }
}
.callFooter {
    margin: auto;
    width: 100%;
    background-color: white;
    bottom: 0%;
    border-radius: 0 0 30px 30px;
    border-top: 1px solid grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    grid-gap: 15%;
}

.callKeypad a,
.callRecents a,
.callContacts a {
    margin: 10% 0 10% 0;
    font-size: 0.9rem;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Fuzzy Bubbles', cursive;
}

@media screen and (max-width: 600px) {
    .callFooter {
        grid-gap: 20px;
    }
}
.callContainer {
    height: 88.8vh;
    position: relative;
    top: 2.2vh;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    color: black;
    border-radius: 20px 20px 30px 30px;
}

@media screen and (max-width: 600px) {
    .callContainer {
        height: 75.5vh;
    }
}
.callHeader {
    margin-top: 3%;
    width: 100%;
    color: black;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid grey;
}

.callHeader h1 {
    margin-left: 30%;
    margin-bottom: 1%;
    font-family: 'Vujahday Script', cursive;
}

@media screen and (max-width: 600px) {
    .callHeader h1 {
        margin-left: 23%;
    }
}
.contactsCont {
    height: 100%;
    background-color: rgb(196, 196, 196);
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.contactsCont::-webkit-scrollbar {
    width: 5px;
}

.contactsCont::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.contactsCont::-webkit-scrollbar-thumb {
    background: #0000ffbb;
    border-radius: 10px;
}
.contactInitial {
    margin: 4% 0 4% 5%;
    font-size: .8rem;
}

.contactCont {
    margin: -2% 2% 2% 2%;
    padding: 3%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-self: center;
    text-align: center;
    position: relative;
    flex-direction: column;
}

.contactTitle {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.contactAvtar {
    padding: 0.5% 0.5% 0.5% 1%;
}

.avtaricon {
    padding: 25%;
    background-color: rgb(255, 0, 0);
    border-radius: 50%;
}

.contactName {
    margin-left: 5%;
    font-size: 1.5rem;
}

.contactAvtar,
.contactName {
    display: flex;
    align-self: center;
    justify-content: center;
}

.contactInitial,
.contactName,
.recentTime {
    font-family: 'Fuzzy Bubbles', cursive;
}

.contactAction {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.icons {
    margin: 3%;
    padding: 3%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.call {
    background-color: rgb(71, 255, 47);
}

.message {
    background-color: rgb(62, 62, 255);
}

.video {
    background-color: rgb(0, 162, 255);
}

@media screen and (max-width: 600px) {
    .avtaricon svg {
        width: 1.5em;
        height: 1.5em;
    }
    .contactName {
        font-size: 1.1rem;
    }
}
.keypadBody {
    height: 100%;
    margin-top: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: scroll;
}

.keypadInput input {
    padding: 3% 0 3% 0;
    color: black;
    background-color: white;
    font-size: 1.5rem;
    font-family: 'Fuzzy Bubbles', cursive;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    outline: 0;
}

.keypadInput input:disabled {
    cursor: not-allowed;
}

.keypadNumber {
    margin-top: 5%;
}

.rowOne,
.rowTwo,
.rowThree,
.rowFour {
    margin: 5% 0 5% 0;
    display: flex;
    grid-gap: 50px;
    align-items: center;
    justify-content: center;
}

.rowOne [type=button],
.rowTwo [type=button],
.rowThree [type=button],
.rowFour [type=button],
.rowFour button {
    background-color: white;
    font-size: 3.5rem;
    font-family: 'Fuzzy Bubbles', cursive;
    border: 0;
    outline: 0;
    cursor: pointer;
}

.callIcon button {
    width: 50px;
    height: 50px;
    background-color: #37d67a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .keypadBody {
        margin-top: 0%;
    }
    .keypadInput input {
        font-size: 1.1rem;
    }
    .rowOne [type=button],
    .rowTwo [type=button],
    .rowThree [type=button],
    .rowFour [type=button],
    .rowFour button {
        margin: 0%;
        font-size: 6vh;
    }
}

.keypadBody::-webkit-scrollbar {
    width: 0px;
}
.recentsCont {
    background-color: rgb(223, 223, 223);
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.recentsCont::-webkit-scrollbar {
    width: 5px;
}

.recentsCont::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.recentsCont::-webkit-scrollbar-thumb {
    background: #0000ffbb;
    border-radius: 10px;
}
.recentDay {
    margin: 4% 0 4% 5%;
    font-size: .8rem;
}

.recentscallCont {
    margin: -2% 2% 2% 2%;
    padding: 3%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-self: center;
    text-align: center;
    position: relative;
}

.recentIcon,
.recentName,
.recentTime,
.recentNumber {
    display: flex;
    align-self: center;
    justify-content: center;
}

.recentDay,
.recentName,
.recentTime,
.recentNumber {
    font-family: 'Fuzzy Bubbles', cursive;
}

.recentName {
    margin-left: 5%;
    font-size: 1.5rem;
}

.recentNumber {
    margin-left: 2%;
}

.recentTime {
    font-size: 0.6rem;
    position: absolute;
    right: 4%;
}

@media screen and (max-width: 600px) {
    .recentName {
        font-size: 1.1rem;
    }
    .recentTime {
        margin-left: 13vw;
    }
}
.slide1 {
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 50px;
    grid-row-gap: 60px;
}

.slide1 a {
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .slide1 {
        grid-column-gap: 25px;
        grid-row-gap: 50px;
    }
}
.slide2 {
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 60px;
}

.slide2 a {
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .slide2 {
        grid-column-gap: 35px;
        grid-row-gap: 50px;
    }
}

.projectsCont {
    height: 88.8vh;
    position: relative;
    top: 2.2vh;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    color: black;
    border-radius: 20px 20px 30px 30px;
}

.projectsBody {
    overflow-y: scroll;
}

.projectsBody::-webkit-scrollbar {
    width: 5px;
}

.projectsBody::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.projectsBody::-webkit-scrollbar-thumb {
    background: #0000ffbb;
    border-radius: 10px;
}

.reactBody {
    border-radius: 0 0 20px 20px;
}

.projectsHeader {
    margin-top: 1%;
    width: 100%;
    color: black;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid grey;
}

.projectsHeader svg {
    height: 100%;
    justify-content: center;
    align-items: center;
}

.projectsHeader h1 {
    margin-left: 30%;
    margin-bottom: 1%;
    font-family: 'Vujahday Script', cursive;
}

.convHeader h1 {
    margin-left: 20%;
}

.javaHeader h1 {
    margin-left: 10%;
    font-size: 2rem;
}

.reactHeader h1 {
    margin-left: 15%;
    font-size: 2rem;
}

.accordion-header {
    font-family: 'Fuzzy Bubbles', cursive;
}

.accordion-button {
    font-size: 1.5rem !important;
}

.accordion-body {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
}

.code:before {
    background: #FF6666;
}

.code span {
    color: #800000;
}

.demo:before {
    background: #81d5ff;
}

.demo span {
    color: #0080ff;
}

.demo:hover:before {
    background: #b1dae7;
}

.button {
    position: relative;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    display: flex;
    align-items: center;
}

.button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
}

.button span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.05em;
}

.button svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    transform: translateX(-5px);
    transition: all 0.3s ease;
}

.button:hover:before {
    width: 100%;
}

.button:hover svg {
    transform: translateX(0);
}

.button:active {
    transform: scale(0.95);
}

@media screen and (max-width: 600px) {
    .projectsCont {
        height: 75.5vh;
    }
    .projectsHeader h1 {
        margin-left: 23%;
    }
    .accordion-button {
        font-size: 1rem !important;
    }
    .accordion-body {
        grid-row-gap: 10px;
    }
}
.messageCont {
    height: 88.8vh;
    position: relative;
    top: 2.2vh;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    color: black;
    border-radius: 20px 20px 30px 30px;
    overflow-y: scroll;
}

.messageCont::-webkit-scrollbar {
    width: 0px;
}

.messageHeader {
    margin-top: 1%;
    width: 100%;
    color: black;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid grey;
}

.messageHeader svg {
    height: 100%;
    justify-content: center;
    align-items: center;
}

.messageHeader h1 {
    margin-left: 25%;
    margin-bottom: 1%;
    font-family: 'Vujahday Script', cursive;
}

@media screen and (max-width: 600px) {
    .messageCont {
        height: 75.5vh;
    }
    .messageHeader h1 {
        margin-left: 23%;
    }
}
.formCont {
    height: 100%;
    padding: 10%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.formGroup {
    margin: 20px 0;
    position: relative;
    padding: 10px 0 0;
    font-family: 'Fuzzy Bubbles', cursive;
}

.formField {
    font-family: inherit;
    width: 100%;
    border: 0;
    color: blue;
    border-bottom: 2px solid #000000;
    outline: 0;
    font-size: 1.3rem;
    padding: 14px 0 0 0;
    background: transparent;
    transition: border-color 0.2s;
}

.formField:-ms-input-placeholder {
    color: transparent;
}

.formField::placeholder {
    color: transparent;
}

.formField:-ms-input-placeholder~.formLabel {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
}

.formField:placeholder-shown~.formLabel {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
}

.formLabel {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
}

.formField:focus {
    color: #000000;
    padding-bottom: 6px;
    font-weight: 700;
    border-bottom: 3px solid blue;
    border-image-slice: 1;
}

.formField:focus~.formLabel {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: blue;
    font-weight: 700;
}

.formSubmit {
    margin-top: 20%;
}

.submit:before {
    background: #77ffa0;
}

.submit span {
    color: #006400;
}

.submit:hover:before {
    background: #2cff6b;
}

.button {
    position: relative;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    display: flex;
    align-items: center;
}

.button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
}

.button span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.05em;
}

.button svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    transform: translateX(-5px);
    transition: all 0.3s ease;
}

.button:hover:before {
    width: 100%;
}

.button:hover svg {
    transform: translateX(0);
}

.button:active {
    transform: scale(0.95);
}
.appContainer {
    position: relative;
    top: -2vh;
    color: white;
}

.navBar {
    padding: 0.5% 2% 0 2%;
    position: absolute;
    top: -1.5%;
    right: 3%;
    border-radius: 20px;
    color: black;
    background-color: rgba(220, 220, 220, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Fuzzy Bubbles', cursive;
}

.swiper {
    width: 100%;
    height: 81vh;
    position: relative;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination {
    position: relative;
    top: 74vh;
    text-align: center;
    z-index: 20;
    color: white;
}

.swiper-pagination-bullet {
    position: relative;
    height: 7px;
    width: 7px;
    background-color: rgb(139, 139, 139);
    border-radius: 50%;
    display: inline-flex;
    transition: all 0.3s;
    margin: 2px;
    cursor: pointer;
}

.swiper-pagination-bullet-active {
    background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 600px) {
    .navBar {
        font-size: 0.7rem;
        top: -1.5%;
        right: 5%;
    }
    .swiper {
        height: 68vh;
    }
    .swiper-pagination {
        top: 62.8vh;
        z-index: 20;
    }
}
