@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&family=Vujahday+Script&&display=swap');
.slide2 {
    margin-top: 5%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 60px;
    grid-row-gap: 60px;
}

.slide2 a {
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .slide2 {
        grid-column-gap: 35px;
        grid-row-gap: 50px;
    }
}

.projectsCont {
    height: 88.8vh;
    position: relative;
    top: 2.2vh;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    color: black;
    border-radius: 20px 20px 30px 30px;
}

.projectsBody {
    overflow-y: scroll;
}

.projectsBody::-webkit-scrollbar {
    width: 5px;
}

.projectsBody::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.projectsBody::-webkit-scrollbar-thumb {
    background: #0000ffbb;
    border-radius: 10px;
}

.reactBody {
    border-radius: 0 0 20px 20px;
}

.projectsHeader {
    margin-top: 1%;
    width: 100%;
    color: black;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid grey;
}

.projectsHeader svg {
    height: 100%;
    justify-content: center;
    align-items: center;
}

.projectsHeader h1 {
    margin-left: 30%;
    margin-bottom: 1%;
    font-family: 'Vujahday Script', cursive;
}

.convHeader h1 {
    margin-left: 20%;
}

.javaHeader h1 {
    margin-left: 10%;
    font-size: 2rem;
}

.reactHeader h1 {
    margin-left: 15%;
    font-size: 2rem;
}

.accordion-header {
    font-family: 'Fuzzy Bubbles', cursive;
}

.accordion-button {
    font-size: 1.5rem !important;
}

.accordion-body {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
}

.code:before {
    background: #FF6666;
}

.code span {
    color: #800000;
}

.demo:before {
    background: #81d5ff;
}

.demo span {
    color: #0080ff;
}

.demo:hover:before {
    background: #b1dae7;
}

.button {
    position: relative;
    margin: auto;
    padding: 12px 18px;
    transition: all 0.2s ease;
    border: none;
    background: none;
    display: flex;
    align-items: center;
}

.button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    border-radius: 50px;
    width: 45px;
    height: 45px;
    transition: all 0.3s ease;
}

.button span {
    position: relative;
    font-family: "Ubuntu", sans-serif;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 0.05em;
}

.button svg {
    position: relative;
    top: 0;
    margin-left: 10px;
    transform: translateX(-5px);
    transition: all 0.3s ease;
}

.button:hover:before {
    width: 100%;
}

.button:hover svg {
    transform: translateX(0);
}

.button:active {
    transform: scale(0.95);
}

@media screen and (max-width: 600px) {
    .projectsCont {
        height: 75.5vh;
    }
    .projectsHeader h1 {
        margin-left: 23%;
    }
    .accordion-button {
        font-size: 1rem !important;
    }
    .accordion-body {
        grid-row-gap: 10px;
    }
}