* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-image: linear-gradient(225deg, #1e3c72 10%, #2a5298 90%);
    overflow: hidden;
}

.body {
    width: 100%;
    height: 100%;
    position: relative;
    margin-top: 1%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

@media screen and (max-width: 600px) {
    body {
        background: none;
    }
    .body {
        margin-top: 7%;
    }
}