@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&display=swap');
.contactInitial {
    margin: 4% 0 4% 5%;
    font-size: .8rem;
}

.contactCont {
    margin: -2% 2% 2% 2%;
    padding: 3%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-self: center;
    text-align: center;
    position: relative;
    flex-direction: column;
}

.contactTitle {
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.contactAvtar {
    padding: 0.5% 0.5% 0.5% 1%;
}

.avtaricon {
    padding: 25%;
    background-color: rgb(255, 0, 0);
    border-radius: 50%;
}

.contactName {
    margin-left: 5%;
    font-size: 1.5rem;
}

.contactAvtar,
.contactName {
    display: flex;
    align-self: center;
    justify-content: center;
}

.contactInitial,
.contactName,
.recentTime {
    font-family: 'Fuzzy Bubbles', cursive;
}

.contactAction {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
}

.icons {
    margin: 3%;
    padding: 3%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.call {
    background-color: rgb(71, 255, 47);
}

.message {
    background-color: rgb(62, 62, 255);
}

.video {
    background-color: rgb(0, 162, 255);
}

@media screen and (max-width: 600px) {
    .avtaricon svg {
        width: 1.5em;
        height: 1.5em;
    }
    .contactName {
        font-size: 1.1rem;
    }
}