.footerContainer {
    position: relative;
    margin: auto;
    height: 11.6%;
    display: grid;
    align-items: center;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 0px;
}

@media screen and (max-width: 600px) {
    .footerContainer {
        height: 9.6%;
        margin: auto;
        grid-column-gap: 0px;
    }
}