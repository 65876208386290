.appContainer {
    position: relative;
    top: -2vh;
    color: white;
}

.navBar {
    padding: 0.5% 2% 0 2%;
    position: absolute;
    top: -1.5%;
    right: 3%;
    border-radius: 20px;
    color: black;
    background-color: rgba(220, 220, 220, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Fuzzy Bubbles', cursive;
}

.swiper {
    width: 100%;
    height: 81vh;
    position: relative;
}

.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-pagination {
    position: relative;
    top: 74vh;
    text-align: center;
    z-index: 20;
    color: white;
}

.swiper-pagination-bullet {
    position: relative;
    height: 7px;
    width: 7px;
    background-color: rgb(139, 139, 139);
    border-radius: 50%;
    display: inline-flex;
    transition: all 0.3s;
    margin: 2px;
    cursor: pointer;
}

.swiper-pagination-bullet-active {
    background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 600px) {
    .navBar {
        font-size: 0.7rem;
        top: -1.5%;
        right: 5%;
    }
    .swiper {
        height: 68vh;
    }
    .swiper-pagination {
        top: 62.8vh;
        z-index: 20;
    }
}