@import url('https://fonts.googleapis.com/css2?family=Vujahday+Script&&display=swap');
.callHeader {
    margin-top: 3%;
    width: 100%;
    color: black;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid grey;
}

.callHeader h1 {
    margin-left: 30%;
    margin-bottom: 1%;
    font-family: 'Vujahday Script', cursive;
}

@media screen and (max-width: 600px) {
    .callHeader h1 {
        margin-left: 23%;
    }
}