@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&display=swap');
.recentDay {
    margin: 4% 0 4% 5%;
    font-size: .8rem;
}

.recentscallCont {
    margin: -2% 2% 2% 2%;
    padding: 3%;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-self: center;
    text-align: center;
    position: relative;
}

.recentIcon,
.recentName,
.recentTime,
.recentNumber {
    display: flex;
    align-self: center;
    justify-content: center;
}

.recentDay,
.recentName,
.recentTime,
.recentNumber {
    font-family: 'Fuzzy Bubbles', cursive;
}

.recentName {
    margin-left: 5%;
    font-size: 1.5rem;
}

.recentNumber {
    margin-left: 2%;
}

.recentTime {
    font-size: 0.6rem;
    position: absolute;
    right: 4%;
}

@media screen and (max-width: 600px) {
    .recentName {
        font-size: 1.1rem;
    }
    .recentTime {
        margin-left: 13vw;
    }
}