.messageCont {
    height: 88.8vh;
    position: relative;
    top: 2.2vh;
    display: flex;
    flex-direction: column;
    background-color: rgb(255, 255, 255);
    color: black;
    border-radius: 20px 20px 30px 30px;
    overflow-y: scroll;
}

.messageCont::-webkit-scrollbar {
    width: 0px;
}

.messageHeader {
    margin-top: 1%;
    width: 100%;
    color: black;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid grey;
}

.messageHeader svg {
    height: 100%;
    justify-content: center;
    align-items: center;
}

.messageHeader h1 {
    margin-left: 25%;
    margin-bottom: 1%;
    font-family: 'Vujahday Script', cursive;
}

@media screen and (max-width: 600px) {
    .messageCont {
        height: 75.5vh;
    }
    .messageHeader h1 {
        margin-left: 23%;
    }
}