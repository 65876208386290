.recentsCont {
    background-color: rgb(223, 223, 223);
    overflow-y: scroll;
    scroll-behavior: smooth;
}

.recentsCont::-webkit-scrollbar {
    width: 5px;
}

.recentsCont::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

.recentsCont::-webkit-scrollbar-thumb {
    background: #0000ffbb;
    border-radius: 10px;
}