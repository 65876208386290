.appMain {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.appNoti {
    position: absolute;
    right: 5%;
    top: -13%;
}

.appNoti svg,
.appNoti2 svg {
    width: 30%;
    height: 30%;
}

.appNoti2 {
    position: absolute;
    right: -2%;
    top: -13%;
}

.appIcon,
.appName {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.appIcon {
    width: 50px;
    height: 50px;
    background-color: rgba(102, 102, 102, 0.5);
    border-radius: 35%;
}

.appName {
    margin-top: 10%;
    font-size: 0.8em;
    color: white;
    font-family: 'Fuzzy Bubbles', cursive;
}

.appName a {
    text-decoration: none;
}

@media screen and (max-width: 600px) {
    .appNoti {
        right: -2%;
        top: -18%;
    }
    .appNoti svg,
    .appNoti2 svg {
        width: 25%;
        height: 25%;
    }
    .appNoti2 {
        right: -13%;
        top: -19%;
    }
    .appIcon {
        width: 40px;
        height: 40px;
    }
    .appName {
        font-size: 0.6em;
    }
}