@import url('https://fonts.googleapis.com/css2?family=Fuzzy+Bubbles&display=swap');
.keypadBody {
    height: 100%;
    margin-top: 15%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-y: scroll;
}

.keypadInput input {
    padding: 3% 0 3% 0;
    color: black;
    background-color: white;
    font-size: 1.5rem;
    font-family: 'Fuzzy Bubbles', cursive;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 0;
    outline: 0;
}

.keypadInput input:disabled {
    cursor: not-allowed;
}

.keypadNumber {
    margin-top: 5%;
}

.rowOne,
.rowTwo,
.rowThree,
.rowFour {
    margin: 5% 0 5% 0;
    display: flex;
    grid-gap: 50px;
    align-items: center;
    justify-content: center;
}

.rowOne [type=button],
.rowTwo [type=button],
.rowThree [type=button],
.rowFour [type=button],
.rowFour button {
    background-color: white;
    font-size: 3.5rem;
    font-family: 'Fuzzy Bubbles', cursive;
    border: 0;
    outline: 0;
    cursor: pointer;
}

.callIcon button {
    width: 50px;
    height: 50px;
    background-color: #37d67a;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    outline: 0;
    cursor: pointer;
}

@media screen and (max-width: 600px) {
    .keypadBody {
        margin-top: 0%;
    }
    .keypadInput input {
        font-size: 1.1rem;
    }
    .rowOne [type=button],
    .rowTwo [type=button],
    .rowThree [type=button],
    .rowFour [type=button],
    .rowFour button {
        margin: 0%;
        font-size: 6vh;
    }
}

.keypadBody::-webkit-scrollbar {
    width: 0px;
}